<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">經銷商管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="!isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增經銷商
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span 
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              initData(row);
              callback();
            }
          "
          @edit="onGridEdit"
          @modalResize="onGridModalResize"
          @removeSelectedRows="onGridRemoveSelectedRows"
          @currentRowChanged="onGridCurrentRowChanged"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              :title-width="100"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
        </grid>
      </div>
      <div class="post intro-y overflow-hidden box mt-5">
        <div
          class="post__tabs nav-tabs flex flex-col sm:flex-row bg-gray-300 dark:bg-dark-2 text-gray-600"
        >
          <Tippy
            tag="a"
            content="顯示經銷商帳號清單"
            data-toggle="tab"
            data-target="#store"
            href="javascript:;"
            class="w-full sm:w-40 py-4 text-center flex justify-center items-center active"
          >
            <UsersIcon class="w-4 h-4 mr-2" /> 帳號管理
          </Tippy>
          <Tippy
            tag="a"
            content="顯示經銷商已完成訂單"
            data-toggle="tab"
            data-target="#order"
            href="javascript:;"
            class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          >
            <TruckIcon class="w-4 h-4 mr-2" /> 完成訂單
          </Tippy>
          <Tippy
            tag="a"
            content="顯示經銷商目前購物車內容"
            data-toggle="tab"
            data-target="#shoppingCart"
            href="javascript:;"
            class="w-full sm:w-40 py-4 text-center flex justify-center items-center"
          >
            <ShoppingCartIcon class="w-4 h-4 mr-2" /> 購物車內容
          </Tippy>
        </div>
        <div class="post__content tab-content">
          <div id="store" class="tab-content__pane px-5 active">
            <div v-if="!(masterRow.Id > 0)" class="h-16 flex items-center m-4">
              <div
                class="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-14 text-theme-10 w-1/2"
              >
                <AlertCircleIcon class="w-6 h-6 mr-2" /> 請選取經銷商資料
              </div>
            </div>
            <store-grid
              :id="masterRow.Id"
              :title="detailTitle"
              v-if="masterRow.Id > 0"
            ></store-grid>
          </div>

          <div id="order" class="tab-content__pane px-5">
            <div class="h-16 flex items-center m-4">
              <div
                class="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-14 text-theme-10 w-1/2"
              >
                <AlertCircleIcon class="w-6 h-6 mr-2" /> order
              </div>
            </div>
          </div>
          <div id="shoppingCart" class="tab-content__pane px-5">
            <div class="h-16 flex items-center m-4">
              <div
                class="rounded-md flex items-center px-5 py-4 mb-2 bg-theme-14 text-theme-10 w-1/2"
              >
                <AlertCircleIcon class="w-6 h-6 mr-2" /> shoppingCart
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>
<style>
textarea {
  min-height: 140px;
}
.vxe-select-option {
  max-width: 100% !important;
}
</style>

<script>
import { onMounted } from "vue";
import CloudFun, { defineComponent, ref } from "@cloudfun/core";
import Grid from "@/cloudfun/components/Grid.vue";
import StoreGrid from "./store/Main";
export default defineComponent({
  components: {
    Grid,
    StoreGrid,
  },
  setup() {
    const model = CloudFun.current?.model;
    const masterRow = ref({});
    const grid = ref({});
    const detailTitle = ref({});
    const storeGrades = ref([]);

    var printColumns = [
      { field: "Name" },
      { field: "Author" },
      { field: "Price" },
      { field: "MemberPrice" },
      { field: "PublishedString" },
      { field: "Ordinal" },
    ];

    const gridOptions = {
      title: "經銷商",
      canCreateRow: true,
      canUpdateRow: true,
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "經銷商清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "經銷商清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Number",
          title: "經銷商代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Name",
          title: "經銷商名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "ShortName",
          title: "簡稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "GradeId",
          title: "等級",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$select",
            immediate: true,
            attrs: { type: "text" },
            options: storeGrades,
          },
        },
        {
          field: "TaxId",
          title: "統一編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Principal.Name",
          title: "負責人姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Phone",
          title: "電話號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Fax",
          title: "傳真號碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
        {
          field: "Address.Line",
          title: "地址",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" },
          },
        },
      ],
      promises: {
        query: model
          ? (params) => model.dispatch("organization/query", params)
          : undefined,
        queryAll: model
          ? () =>
              model.dispatch("organization/query", {
                keyword: grid.value.keyword,
              })
          : undefined,
        save: model
          ? (params) => model.dispatch("organization/save", params)
          : undefined,
      },
      modalConfig: { height: "Auto" },
    };
    const formOptions = {
      items: [
        {
          field: "Number",
          title: "經銷商代號",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Name",
          title: "經銷商名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "ShortName",
          title: "簡稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "GradeId",
          title: "等級",
          itemRender: {
            name: "$select",
            options: storeGrades,
          },
        },
        {
          field: "TaxId",
          title: "統一編號",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Principal.Name",
          title: "負責人姓名",
          span: 12,
          itemRender: {
            name: "$input",
            itemRender: {
              name: "$input",
              props: { placeholder: "請輸入文字" },
            },
          },
        },
        {
          field: "Phone",
          title: "電話號碼",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Fax",
          title: "傳真號碼",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Email",
          title: "Email",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          field: "Address.Line",
          title: "地址",
          span: 24,
          itemRender: { name: "$input", props: { placeholder: "請輸入文字" } },
        },
        {
          span: 24,
          align: "right",
          itemRender: {
            name: "$buttons",
            children: [
              { props: { type: "submit", content: "確定", status: "primary" } },
              { props: { type: "reset", content: "重置" } },
            ],
          },
        },
      ],
      rules: {
        Name: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入經銷商名稱",
          },
        ],
        Number: [
          {
            type: "string",
            max: 128,
            required: true,
            message: "請輸入經銷商編號",
          },
        ],
        // Price: [{ type: "number", required: true, message: "售價" }],
        // MemberPrice: [{ type: "number", required: true, message: "會員價" }],
        // Published: [{ required: true }],
      },
    };

    const initData = (row) => {
      row.Id = 0;
      row.Name = "";
      row.TaxId = "";
      row.Number = "";
      row.Principal = {
        Name: "",
      };
      row.Address = { Line: "" };
    };

    // model?.dispatch("storeGrade/query", null).then(
    //   (value) => {
    //     console.log("value", value);
    //     // storeGrades.value = value.map((el) => {
    //     //   value:el.Id,
    //     //   label:el.Name
    //     //   });
    //   },
    //   (reason) => {
    //     debugger;
    //     CloudFun.send("error", { subject: "操作失敗！", content: reason });
    //   }
    // );

    onMounted(() => {
      console.log("");
      model?.dispatch("storeGrade/query").then(
        (value) => {
          console.log("value", value);
          storeGrades.value = value.data.map((el) => {
            return {
              value: el.Id,
              label: el.Name,
            };
          });
        },
        (reason) => {
          CloudFun.send("error", { subject: "操作失敗！", content: reason });
        }
      );
    });

    return {
      initData,
      masterRow,
      detailTitle,
      grid,
      gridOptions,
      formOptions,
      storeGrades,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
    };
  },
  methods: {
    onGridModalResize(size) {
      this.sliderWidth = `${size.width - 100}px`;
    },
    async onGridEdit(row, callback) {
      const entity = row.Id
        ? await this.$model.dispatch("organization/find", row.Id)
        : undefined;
      if (entity) Object.assign(row, entity);
      callback();
    },
    onGridRemoveSelectedRows(rows, callback) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    onGridCurrentRowChanged(row) {
      this.masterRow = row;
      this.detailTitle = `${this.masterRow.Number} / ${this.masterRow.Name}`;
    },
  },
});
</script>
