<template>
  <div>
    <dx-popup
      ref="modal"
      :visible="show"
      :title="title"
      width="400"
      height="Auto"
      @hidden="close"
    >
      <div class="h-full">
        <div class="w-full flex">
          <div class="w-full mx-5">
            <form @submit.prevent="onSubmit">
              <dx-form :form-data="formData" validation-group="form">
                <dx-group-item :col-count="1">
                  <dx-simple-item
                    data-field="password"
                    editor-type="dxTextBox"
                    :label="{ text: '變更新密碼', alignment: 'right' }"
                    :editor-options="{
                      showClearButton: true,
                      mode: 'password',
                      placeholder: '輸入新密碼',
                    }"
                  >
                    <DxRequiredRule></DxRequiredRule>
                  </dx-simple-item>

                  <dx-simple-item col-span="2">
                    <template #default>
                      <div class="grid justify-items-end">
                        <dx-button
                          icon="check"
                          text="儲存"
                          type="default"
                          validation-group="form"
                          use-submit-behavior="true"
                        ></dx-button>
                      </div>
                    </template>
                  </dx-simple-item>
                </dx-group-item>
              </dx-form>
            </form>
          </div>
        </div>
      </div>
    </dx-popup>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, {
  defineComponent,
  ref,
  computed,
  reactive,
} from "@cloudfun/core";
import { DxPopup } from "devextreme-vue";
import { watch } from "vue";
import {
  DxSelectBox,
  DxNumberBox,
  DxTextArea,
  DxTextBox,
  DxDropDownBox,
  DxTreeView,
  DxTagBox,
  DxSwitch,
  DxButton,
  DxLoadPanel,
} from "devextreme-vue";

import {
  DxForm,
  DxItem,
  DxSimpleItem,
  DxGroupItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";

export default defineComponent({
  components: {
    DxPopup,
    DxForm,
    DxSelectBox,
    DxNumberBox,
    DxTextArea,
    DxSimpleItem,
    DxGroupItem,
    DxItem,
    DxTextBox,
    DxDropDownBox,
    DxTreeView,
    DxTagBox,
    DxLabel,
    DxSwitch,
    DxButton,
    DxRequiredRule,
    DxLoadPanel,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
    },
  },
  setup(props) {
    const model = CloudFun.current?.model;
    const modal = ref({});
    const formData = reactive({ key: props.id, password: "" });
    const title = ref("");
    // const formData = computed(() => props.data);

    watch(
      () => props.data,
      () => {
        console.log("props.product", props.data);
        formData.key = props.data.Id;
        title.value = `${props.data.Account}變更密碼`;
      }
    );

    console.log("props", props.data);
    return {
      modal,
      formData,
      title,
    };
  },

  methods: {
    close() {
      this.formData = {};
      this.$emit("update:show", false);
    },

    onSubmit(e) {
      console.log("formData", this.formData);

      const action = () => {
        this.formData = {};
        this.$emit("update:show", false);
      };

      if (this.$attrs.onSave) this.$emit("save", this.formData, action);
      else action();
      e.preventDefault();
    },
  },
});
</script>

<style scoped></style>
